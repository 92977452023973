import { useMutation } from '@apollo/client'
import * as UpChunk from '@mux/upchunk'
import { Button } from 'components/Button'
import { mutate } from 'graphql/mediaGallery'
import { StreamPageContext } from 'hooks/context/StreamPageContext'
import { useInsertActivityFeed, useUser } from 'hooks/index'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { PlayerContext } from 'pages/club/[club_slug]/player/[player_slug]'
import { useContext, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import useSwr from 'swr'
import { getCurrentPageName, slugifyString } from 'utils/common-helper'
import { trackEvent } from 'utils/helper-amplitude'
import { s3UploadFile } from 'utils/s3-helper'
import { createUploadURL } from '../helper'
import { MediaGalleryContext } from '../index'
import { AddMediaButtonWrapper, CustomText } from '../mediagallery.style'

const fetcher = (url: any) => {
    return fetch(url).then((res) => res.json())
}

const AddMediaButton = (props: any) => {
    const { clubInfo } = props
    const { mediaGalleryAccess, mode, playerId } =
        useContext(MediaGalleryContext)
    const [isUploading, setIsUploading] = useState(false)
    const [isPreparing, setIsPreparing] = useState(false)
    const [uploadId, setUploadId] = useState(null)
    const [progress, setProgress] = useState<number | null>(null)
    const [showWarning, setShowWarning] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const user: any = useUser()
    const router = useRouter()
    const { asset_id } = router.query
    const { match_id } = useContext(StreamPageContext)
    const player = useContext(PlayerContext)

    const [inserMediaFile] = useMutation(mutate.ADD_MEDIA_FILE, {
        onCompleted() {
            toast.success(`Added media file to the media gallery`)
        },
        onError(e) {
            toast.error(
                `Error while adding media file to the media gallery` + e,
            )
        },
    })

    const [addTagClub] = useMutation(mutate.ADD_CLUB_TAG, {
        onError(e) {
            toast.error(
                `Something went wrong while adding tag to the media file.`,
            )
        },
    })

    const [addTagPlayer] = useMutation(mutate.ADD_PLAYER_TAG, {
        onError(e) {
            toast.error(
                `Something went wrong while adding tag to the media file.`,
            )
        },
    })

    const { data } = useSwr(
        () => (isPreparing ? `/api/mediaGallery/${uploadId}` : null),
        fetcher,
        { refreshInterval: 5000 },
    )

    const upload = data && data.upload

    const insertFeed = useInsertActivityFeed()

    useEffect(() => {
        async function insertVideo() {
            if (!upload && !upload?.asset_id) return

            const mediaFileObject = {
                club_id: clubInfo.id,
                match_id: asset_id,
                playback_id: upload.playback_id,
                asset_id: upload.asset_id,
                url: upload.asset_id,
                upload_id: upload.upload_id,
                added_by: user.user?.id,
            }

            const { data } = await inserMediaFile({
                variables: {
                    objects: mediaFileObject,
                },
            })

            const mediaGalleryInfo =
                data.insert_media_gallery.returning[0] ?? null
            await handleClubPlayerTag(mediaGalleryInfo)

            setIsPreparing(false)
        }

        insertVideo()
    }, [upload])

    const handleOnClick = async (event: any) => {
        const newFile = event?.target.files[0]

        //10MB = 10000KB = 10000000 bytes
        if (newFile.size > 10000000) {
            toast.warning('File exceeded the maximum size of 10mb')
            return
        }

        const regex = /\.(jpe?g|png)$/i
        regex.test(newFile.name)
            ? onUploadImage(newFile)
            : onUploadVideo(newFile)
    }

    const onUploadImage = async (file: File) => {
        const slug = slugifyString(file.name)
        let mediaFile: string | null = null

        if (!_.isNull(file)) {
            try {
                setIsUploading(true)

                const s3res: any = await s3UploadFile(
                    'MediaGallery',
                    slug,
                    file,
                )
                mediaFile = s3res.location

                const mediaFileObject = {
                    club_id: clubInfo.id,
                    match_id: asset_id,
                    url: mediaFile,
                    added_by: user.user?.id,
                }

                const { data } = await inserMediaFile({
                    variables: {
                        objects: mediaFileObject,
                    },
                })

                const mediaGalleryInfo =
                    data.insert_media_gallery.returning[0] ?? null

                await handleClubPlayerTag(mediaGalleryInfo)
                await handleActivityFeed(mediaGalleryInfo)
            } catch (e) {
                console.log(`Upload media file error: `, e)
                toast.error(
                    `Error while adding media file to the media gallery` + e,
                )
            } finally {
                setIsUploading(false)
            }
        }
    }

    const handleClubPlayerTag = async (mediaGalleryInfo: any) => {
        // tag player
        if (mode === 'player' && playerId) {
            const mediaTagPlayerObj = {
                player_id: playerId,
                media_gallery_id: mediaGalleryInfo?.id,
            }

            const { data: playerTag } = await addTagPlayer({
                variables: {
                    objects: mediaTagPlayerObj,
                },
            })

            const mediaPlayerTagId =
                playerTag.insert_media_gallery_players.returning[0].id

            // insert activity feed for tagging player
            const feedInput = {
                player_id: playerId,
                type: 'media_gallery',
                ref_id: mediaPlayerTagId,
                action: 'tag',
            }

            await insertFeed([feedInput])
        }

        if (mode === 'club' || mode === 'player') {
            const mediaTagClubObj = {
                club_id: clubInfo.id,
                media_gallery_id: mediaGalleryInfo?.id,
            }

            await addTagClub({
                variables: {
                    objects: mediaTagClubObj,
                },
            })
        }
    }

    // handle inserting of activity feed when player uploads media gallery
    const handleActivityFeed = async (mediaGalleryInfo: any) => {
        if (_.isEmpty(user?.user?.players_details)) return

        const feedInput = {
            player_id: user?.user?.players_details[0]?.id ?? null,
            type: 'media_gallery',
            ref_id: mediaGalleryInfo?.id,
            action: 'upload',
        }

        await insertFeed([feedInput])
    }

    const onUploadVideo = async (file: File) => {
        const data = await createUploadURL()

        setUploadId(data.id)
        setIsUploading(true)

        const upload = UpChunk.createUpload({
            endpoint: data.url,
            file: file,
        })

        upload.on('error', (e) => {
            toast.error(`Error while adding video to the media gallery` + e)
            setIsUploading(false)
            setProgress(0)
        })

        upload.on('progress', (progress) => {
            setProgress(Math.floor(progress.detail))
        })

        upload.on('success', () => {
            setIsPreparing(true)
            setIsUploading(false)
            setProgress(0)
        })
    }

    const onTargetClick = () => {
        if (!user.user || !mediaGalleryAccess) {
            setShowWarning(true)

            return
        }

        if (fileInputRef && fileInputRef.current) {
            fileInputRef?.current.click()
        }

        getCurrentPageName(router?.pathname) === 'Club Page' &&
            trackEvent(user.user, `Upload content to Media Gallery`, {
                clubName: clubInfo?.name,
            })
        getCurrentPageName(router?.pathname) === 'Player Profile Page' &&
            trackEvent(user.user, `Upload content to Media Gallery`, {
                playerName: `${player?.player?.user?.first_name} ${player?.player?.user?.last_name}`,
            })
        getCurrentPageName(router?.pathname) === 'GameDay Page' &&
            trackEvent(user.user, `Add to Media Gallery`, { matchID: match_id })
    }

    return (
        <AddMediaButtonWrapper>
            {showWarning && (
                <CustomText mode="warning">
                    {
                        'Please Login as an Admin User or Digital Fan to add to Media Gallery'
                    }
                </CustomText>
            )}
            <input
                onChange={handleOnClick}
                onClick={(event: any) => {
                    event.target.value = null
                }}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/*, video/*"
            />
            <Button
                bColor="warning"
                bSize="medium"
                style={{
                    width: '200px',
                    height: '10px',
                    fontSize: 12,
                    padding: '15px',
                }}
                disabled={isUploading}
                onClick={onTargetClick}
            >
                {isUploading
                    ? 'Uploading media file...'
                    : 'Add Media to Gallery'}{' '}
                {isUploading && progress && `${progress}%`}
            </Button>
        </AddMediaButtonWrapper>
    )
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(AddMediaButton)
