import marker from 'assets/images/home/mark.png'
import ThumbnailCard from 'components/Card/ThumbnailCard/ThumbnailCard'
import { Col, Row } from 'components/Layout'
import { LazySkeletonWrapper } from 'components/Skeleton'
import { Text } from 'components/Text'
import useMediaQuery from 'hooks/useMediaQuery'
import { scrollerLoadContentEvent } from 'lib/tracking/events/scroll'
import _ from 'lodash'
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setVideoAutoplay } from 'redux/actions/site'
import { EventElement, Games, MatchElement } from 'restful-client/types/games'
import { EventCardProps, GameCardProps } from 'types/components/GameCard'
import {
    combineEventsAndMatches,
    isLive,
    thumbNailLink,
} from 'utils/common-helper'
import { ScrollerStyling } from '../CardScroller'
import { SeeAll } from '../SeeAll'

type ReplaysFunction = (offset: any) => Promise<Games | undefined>

export type VideoType = 'Live' | 'Replay' | 'Match' | 'Clip' | 'Event'

interface ReplayScrollerProps {
    initialData: any
    getReplaysFunction?: ReplaysFunction
    type: VideoType
    scrollerTitle: string
    page: string
    event: string
    seeAllLink?: string
    lazyLoadSize: number
    backgroundColor?: string
    reverse?: boolean
    emptyElement?: ReactNode
    styling?: ScrollerStyling
    startOff?: boolean
}

const ReplayScroller = (props: ReplayScrollerProps) => {
    const {
        initialData,
        getReplaysFunction,
        type,
        scrollerTitle,
        page,
        event,
        seeAllLink,
        lazyLoadSize,
        backgroundColor,
        reverse,
        emptyElement,
        styling,
        startOff,
    } = props
    const initialReplays = initialData?.replays
    const [offset, setOffset] = useState(lazyLoadSize)
    const [replays, setReplays] =
        useState<(EventElement | MatchElement)[]>(initialReplays)
    const [loadMore, setLoadMore] = useState<boolean>(!startOff)
    const [doneFetch, setDoneFetch] = useState<boolean>(false)
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(768)

    const fetchReplays = async (offset: number, firstSearch = false) => {
        const data = getReplaysFunction && (await getReplaysFunction(offset))

        // This is to prevent the scroller from triggering a 'isVisible' fetch before the initial fetch is complete.
        // This prevents the invalid 'Home' thumbnail bug.
        setDoneFetch(true)

        offset > 0 &&
            scrollerLoadContentEvent({
                type: type,
                offset: offset,
                page: page,
                gtm: true,
                datadog: true,
            })

        if (!data || !data.event_streams || !data.matches) {
            setReplays(initialReplays)
            return
        }

        if (_.isEmpty(data.event_streams) && _.isEmpty(data.matches)) {
            if (firstSearch || !loadMore) {
                setReplays([])
            }
        } else {
            const replayData = combineEventsAndMatches(
                data.event_streams,
                data.matches,
            )
            if (reverse) replayData.reverse()
            if (replayData) {
                const curReplays = firstSearch ? [] : replays
                setReplays([...(curReplays as []), ...replayData])
                setOffset(offset + replayData.length)
            }
        }

        if (data.matches.length + data.event_streams.length < lazyLoadSize) {
            setLoadMore(false)
        }
    }

    useEffect(() => {
        fetchReplays(0, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getReplaysFunction])

    return (
        <>
            <Row alignItems="center" padding={styling?.padding ?? ''}>
                <Col item={24}>
                    <Text
                        fColor="white"
                        fSize={styling?.titleSize ?? 1.5}
                        fWeight={700}
                        mode="h2"
                        padding="0 0 10px 0"
                    >
                        {scrollerTitle}
                    </Text>
                </Col>
                {seeAllLink && (
                    <Col item={24}>
                        <Row flexDirection="row-reverse">
                            <SeeAll href={`/${seeAllLink}`} />
                        </Row>
                    </Col>
                )}
            </Row>

            <LazySkeletonWrapper
                active={loadMore && doneFetch}
                mode={'Replay'}
                cards={isMobile ? 1 : 3}
                data={replays}
                emptyElement={
                    emptyElement ?? ['Day', 'Live'].includes(type)
                        ? 'No scheduled events.'
                        : `No replays available.`
                }
                onChange={(isVisible) => {
                    isVisible && fetchReplays(offset)
                }}
                backgroundColor={backgroundColor}
            >
                <>
                    {replays &&
                        replays.map((match: any, index: number) => {
                            const isEvent =
                                (!match?.home_team_id &&
                                    !match?.away_team_id) ||
                                match.is_event

                            const mode = type === 'Live' ? 'Day' : 'Replay'

                            if (isEvent) {
                                const backgroundImageSport =
                                    match?.event_type === 'club'
                                        ? match?.club?.sport?.background_image
                                              ?.url
                                        : match?.league?.sports
                                              ?.background_image?.url
                                const item: EventCardProps & GameCardProps = {
                                    id: match?.id,
                                    event_name: match?.name,
                                    backgroundImage: thumbNailLink(
                                        match?.stream_info?.video_asset_id,
                                        500,
                                        match?.image,
                                    ),
                                    mode,
                                    isLive: isLive(
                                        match?.start_datetime,
                                        match?.stream_info?.status,
                                    ),
                                    users: 0, //TODO: get the number of users watching
                                    date: match?.start_datetime,
                                    is_event: true,
                                    clubImage1: match?.club?.logo,
                                    clubName1: match?.club?.display_name,
                                    event_slug: match?.slug,
                                    league_slug: match.league.slug,
                                    clubName: match?.club?.name,
                                    leagueName: match?.league?.name,
                                    leagueImage: match.league.logo
                                        ? match.league.logo
                                        : marker,
                                    event_type: match?.event_type,
                                    club_slug: match?.club?.slug,
                                    backgroundImageSport,
                                    backgroundImageLeague:
                                        match?.league?.background_image?.url,
                                }

                                return (
                                    <div
                                        key={`game-day-view-key${index}`}
                                        onClick={() => {
                                            dispatch(setVideoAutoplay(true))
                                        }}
                                    >
                                        <ThumbnailCard {...props} {...item} />
                                    </div>
                                )
                            } else {
                                const item: GameCardProps = {
                                    id: match.id,
                                    sport: match.home_team.sport.name,
                                    backgroundImage: thumbNailLink(
                                        match?.stream_info?.video_asset_id,
                                        500,
                                        match?.thumbnail_url,
                                    ),
                                    clubImage1: match.home_team.club.logo,
                                    clubName1:
                                        match.home_team.club.display_name,
                                    clubImage2: match.away_team.club.logo,
                                    clubName2:
                                        match.away_team.club.display_name,
                                    leagueImage: match.league.logo
                                        ? match.league.logo
                                        : marker,
                                    leagueName: match.league.name,
                                    roundName: match.round_name,
                                    matchName: match.name,
                                    mode,
                                    isLive: isLive(
                                        match.start_datetime,
                                        match?.stream_info?.status,
                                    ),
                                    users: 0, //TODO: get the number of users watching
                                    date: match.start_datetime,
                                    matchClubs: {
                                        match_id: match.id,
                                        home_club_id: match.home_team.club.id,
                                        away_club_id: match.away_team.club.id,
                                        home_slug: match.home_team.club.slug,
                                        away_slug: match.away_team.club.slug,
                                        league_slug: match.league.slug,
                                    },
                                    customOpponent: match.custom_opponent,
                                    is_event: match.is_event,
                                    backgroundImageSport:
                                        match?.sport?.background_image?.url,
                                    backgroundImageLeague:
                                        match?.league?.background_image?.url,
                                }

                                return (
                                    <div
                                        key={`game-day-view-key${index}`}
                                        onClick={() => {
                                            dispatch(setVideoAutoplay(true))
                                        }}
                                    >
                                        <ThumbnailCard
                                            event_name={
                                                match?.name ? match.name : ''
                                            }
                                            {...props}
                                            page={page}
                                            event={event}
                                            {...item}
                                        />
                                    </div>
                                )
                            }
                        })}
                </>
            </LazySkeletonWrapper>
        </>
    )
}

export default ReplayScroller
