import { CardScroller } from 'components/Scroller/CardScroller'
import { useRouter, useUser } from 'hooks'
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { trackEvent } from 'utils/helper-amplitude'

const PlayerView: React.FC = (props: any) => {
    const {
        move,
        query: { team_slug, club_slug },
    } = useRouter()
    const { club, initialData } = props
    const players = initialData?.initialData?.club?.players
    const { user } = useUser()

    const onHandleClick = (slug: string, player: any) => {
        const route = {
            path: `/club/${club.slug}/player/${slug}`,
        }
        move(route.path)

        trackEvent(user, 'View Player Profile (via Club page)', {
            playerName: `${player?.user?.first_name} ${player?.user?.last_name}`,
            clubName: club?.name,
        })
    }

    return (
        <CardScroller
            title="Our Players"
            data={players?.map((player: any) => {
                const name = player?.user?.first_name
                    ? `${player?.user?.first_name ?? ''} ${
                          player?.user?.last_name ?? ''
                      }`
                    : `${player.name} ${player.last_name}`
                const backgroundImage = player?.user?.photo
                    ? player?.user?.photo
                    : player?.image
                      ? player?.image
                      : ''
                return {
                    title: name,
                    image: backgroundImage,
                    slug: player.slug,
                    href: `/club/${club.slug}/player/${player.slug}`,
                }
            })}
            type="Avatar"
            seeAllLink={`/club/${club.slug}/players`}
        />
    )
}

const mapStateToProps = (state: RootState) => ({
    club: state.club.info,
})

const mapDispatchToProps = {}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(PlayerView)
