import { useLazyQuery } from '@apollo/client'
import ThumbnailCard from 'components/Card/ThumbnailCard/ThumbnailCard'
import { Col, Row } from 'components/Layout'
import { FeatureClip_Modal } from 'components/Modal/FeatureClip/feature-clip'
import { Page } from 'components/Page'
import { LAZY_CARD_LOADS } from 'components/Scroller/VideoScroller'
import { LazySkeletonWrapper } from 'components/Skeleton'
import { Text } from 'components/Text'
import GET_CLIPS from 'graphql/clips/queries'
import { useRouter } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'
import { scrollerLoadContentEvent } from 'lib/tracking/events/scroll'
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setClipList } from 'redux/actions/clip'
import { setVideoAutoplay } from 'redux/actions/site'
import { Clips } from 'restful-client/types/clips'
import { SelectedClipType } from 'types/common/clips'
import { FeaturedClip } from 'types/components/FeaturedClip'
import { baseUrl } from 'utils/constData'
import { ScrollerStyling } from '../CardScroller'
import { SeeAll } from '../SeeAll'

type ClipsFunction = (offset: number) => Promise<Clips | undefined>

interface ClipScrollerProps {
    initialData: any
    getClipsFunction?: ClipsFunction
    scrollerTitle: string
    page: string
    pageTitle?: string
    event: string
    seeAllLink?: string
    lazyCardLoads: number
    backgroundColor?: string
    emptyElement?: ReactNode
    styling?: ScrollerStyling
}

export const formatDataMapping = (rawData: any) => {
    const matchDateTime = (clipData: any) => {
        if (clipData?.match) {
            return clipData?.match?.start_datetime
        }
        if (clipData?.event_stream) {
            return clipData?.event_stream?.start_datetime
        }
        return ''
    }

    const description = (clipData: any) => {
        if (clipData?.match) {
            return clipData.match === null
                ? ''
                : clipData.match?.home_team.club.display_name ===
                    clipData.match?.away_team.club.display_name
                  ? `${clipData.match?.home_team.club.display_name} - ${clipData.match?.round_name}`
                  : clipData?.match?.custom_opponent
                    ? `${clipData.match?.home_team.club.display_name} vs ${clipData.match?.custom_opponent?.club_display_name} - ${clipData.match?.round_name}`
                    : `${clipData.match?.home_team.club.display_name} vs ${clipData.match?.away_team.club.display_name} - ${clipData.match?.round_name}`
        }
        if (clipData?.event_stream) {
            return clipData?.event_stream?.name
        }
        return ''
    }

    const formatData = rawData?.map((clipAsset: any) => ({
        id: clipAsset.clip_asset.id,
        leagueName: clipAsset?.clip_asset?.match
            ? clipAsset?.clip_asset?.match?.league?.name
            : clipAsset?.clip_asset?.event_stream?.league?.name,
        clubInfo: clipAsset.club,
        clubId: clipAsset.club_id,
        playbackId: clipAsset.clip_asset.playback_id,
        assetId: clipAsset.clip_asset.asset_id,
        matchId: clipAsset.clip_asset.match?.id,
        staticFileStatus: clipAsset.clip_asset.static_file_status,
        backgroundImage: {
            src:
                'https://image.mux.com/' +
                clipAsset.clip_asset.playback_id +
                '/thumbnail.jpg?width=500',
            height: 314,
            width: 178,
        },
        title: clipAsset.clip_asset.name,
        desc: description(clipAsset?.clip_asset),
        homeClubName:
            clipAsset?.clip_asset?.match === null
                ? ''
                : clipAsset?.clip_asset.match?.home_team?.club?.name,
        awayClubName:
            clipAsset?.clip_asset?.match === null
                ? ''
                : clipAsset?.clip_asset?.match?.away_team?.club?.name,
        homeClubDisplayName:
            clipAsset.clip_asset.match === null
                ? ''
                : clipAsset?.clip_asset.match?.home_team?.club?.display_name,
        awayClubDisplayName:
            clipAsset.clip_asset.match === null
                ? ''
                : clipAsset?.clip_asset.match?.away_team?.club?.display_name,
        homeClubSlug:
            clipAsset.clip_asset.match === null
                ? ''
                : clipAsset?.clip_asset.match?.home_team?.club?.slug,
        awayClubSlug:
            clipAsset.clip_asset.match === null
                ? ''
                : clipAsset?.clip_asset.match?.away_team?.club?.slug,
        matchDateTime: matchDateTime(clipAsset?.clip_asset),
        submittedBy: clipAsset?.clip_asset?.user
            ? `${clipAsset?.clip_asset?.user?.first_name} ${clipAsset?.clip_asset?.user?.last_name}`
            : 'N/A',
        author_id: clipAsset?.clip_asset?.user_id ?? null,
    }))
    return formatData
}

const ClipScroller = (props: ClipScrollerProps) => {
    const {
        initialData,
        getClipsFunction,
        scrollerTitle,
        page,
        pageTitle,
        event,
        seeAllLink,
        lazyCardLoads,
        backgroundColor,
        emptyElement,
        styling,
    } = props
    const { move, query, router } = useRouter()
    const ssrData = initialData?.clips
    const formattedSsrData = formatDataMapping(ssrData)
    const isMobile = useMediaQuery(768)

    const [modalFlag, setModalFlag] = useState<boolean>(false)
    const [clips, setClips] = useState<FeaturedClip[] | null>(formattedSsrData)
    const [selectedClip, setSelectedClip] = useState<SelectedClipType>()

    const [loadMore, setLoadMore] = useState<boolean>(true)
    const [doneFetch, setDoneFetch] = useState<boolean>(false)
    const [offset, setOffset] = useState(LAZY_CARD_LOADS)
    const dispatch = useDispatch()

    const onCloseModal = () => {
        setSelectedClip({} as SelectedClipType)
        setModalFlag(false)

        /** Reset Clips */
        if (typeof query?.fc !== 'undefined') {
            delete router.query.fc
            router.push(router)
            setClips(null)
            setOffset(0)
            fetchClips(0, true)
        }
    }

    const onClipClick = (item: FeaturedClip) => {
        if (item.id) {
            getClip({
                variables: {
                    where: {
                        id: { _eq: item.id },
                    },
                },
            })
            dispatch(setVideoAutoplay(true))
        }

        // setModalFlag(true)
        // setSelectedClip(item as SelectedClipType)
    }

    const fetchClips = async (offset: any, firstSearch = false) => {
        const data = getClipsFunction && (await getClipsFunction(offset))
        setDoneFetch(true)

        offset > 0 &&
            scrollerLoadContentEvent({
                type: 'Clip',
                offset: offset,
                page: page,
                gtm: true,
                datadog: true,
            })

        if (!data || !data.clip_asset_user_club) {
            setClips(formattedSsrData)
            return
        }

        if (data.clip_asset_user_club.length === 0) {
            firstSearch && setClips([])
            !loadMore && setClips([])
        } else {
            const clipData = data?.clip_asset_user_club
            const formatedClips =
                clipData === null ? [] : formatDataMapping(clipData)
            const curClipsData = firstSearch ? [] : clips
            setClips([...(curClipsData as []), ...formatedClips])
            setClipList([...(curClipsData as []), ...formatedClips])
            setOffset(offset + data?.clip_asset_user_club.length)
        }

        if (data.clip_asset_user_club.length < lazyCardLoads) {
            setLoadMore(false)
        }
    }

    const [getClip] = useLazyQuery(GET_CLIPS.GET_CLIPS, {
        onCompleted(data) {
            const clip = data.clip_assets[0]
            const clubInfo = data.clip_assets[0]?.clip_asset_user_clubs[0]?.club
            const author =
                clip.user?.first_name && clip.user?.last_name
                    ? `${clip.user.first_name} ${clip.user.last_name}`
                    : clip.user?.username
            const newSelectedClip: SelectedClipType = {
                id: clip.id,
                clubId: clip.clip_asset_user_clubs[0].club?.id,
                title: clip.name,
                homeClubName: clip.match?.home_team?.club?.name,
                awayClubName: clip.match?.away_team?.club?.name,
                playbackId: clip.playback_id,
                assetId: clip.asset_id,
                matchId: clip.match_id,
                eventStreamsId: clip.event_streams_id,
                eventStreamsSlug: clip.event_stream?.slug,
                staticFileStatus: clip.static_file_status,
                desc: clip.match?.round_name ?? clip.event_stream?.name,
                homeClubDisplayName:
                    clip.match?.home_team?.club?.display_name ??
                    clip.clip_asset_user_clubs[0].club?.display_name,
                awayClubDisplayName: clip.match?.away_team?.club?.display_name,
                homeClubSlug:
                    clip.match?.home_team?.club?.slug ??
                    clip.clip_asset_user_clubs[0].club?.slug,
                awayClubSlug: clip.match?.away_team?.club?.slug,
                matchDateTime: clip.match?.created_at,
                submittedBy: author,
                author_id: clip.user_id,
                leagueName: clip.match?.league?.name,
                leagueSlug:
                    clip.match?.league?.slug ?? clip.event_stream?.league?.slug,
                clubInfo: clubInfo,
            }
            setSelectedClip(newSelectedClip)
            setModalFlag(true)
        },
    })

    useEffect(() => {
        if (typeof query?.fc !== 'undefined') {
            getClip({
                variables: {
                    where: {
                        playback_id: { _eq: query.fc },
                    },
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setClipList(formattedSsrData)
    }, [formattedSsrData])

    useEffect(() => {
        fetchClips(0, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getClipsFunction])

    const scrollerContents = (
        <>
            <Row alignItems="center" padding={styling?.padding ?? ''}>
                <Col item={24}>
                    <Text
                        fColor="white"
                        fSize={styling?.titleSize ?? 1.5}
                        fWeight={700}
                        mode="h2"
                        padding="0 0 10px 0"
                    >
                        {scrollerTitle}
                    </Text>
                </Col>
                {seeAllLink && (
                    <Col item={24}>
                        <Row flexDirection="row-reverse">
                            <SeeAll href={`/${seeAllLink}`} />
                        </Row>
                    </Col>
                )}
            </Row>

            <LazySkeletonWrapper
                active={loadMore && doneFetch}
                emptyElement={emptyElement ?? <Text>No clips available.</Text>}
                mode="Clip"
                cards={isMobile ? 1 : 3}
                data={clips}
                onChange={(isVisible) => {
                    isVisible && fetchClips(offset)
                }}
                backgroundColor={backgroundColor}
            >
                <>
                    {clips?.map((item: FeaturedClip, index: number) => {
                        return (
                            <article key={`clips-card-` + index}>
                                <ThumbnailCard
                                    event_name={''}
                                    {...item}
                                    page={page}
                                    event={event}
                                    mode="Clip"
                                    key={index}
                                    handleClick={() => onClipClick(item)}
                                    href={`${baseUrl}${`/clips/`}${item.id}`}
                                />
                            </article>
                        )
                    })}
                </>
            </LazySkeletonWrapper>

            <FeatureClip_Modal
                {...selectedClip}
                setClips={setClips}
                show={modalFlag}
                handleClose={() => onCloseModal()}
            />
        </>
    )

    return typeof query?.fc !== 'undefined' ? (
        <Page
            description={selectedClip?.desc}
            video={`https://stream.mux.com/${selectedClip?.playbackId}/high.mp4`}
            title={selectedClip?.title}
            image={`https://image.mux.com/${selectedClip?.playbackId}/thumbnail.png`}
            verification={!modalFlag ? `hdq6jfnfk6p689664aa4hj161ku7dz` : ``}
            canonical={`${baseUrl}/clips/${selectedClip?.id}`}
        >
            {scrollerContents}
        </Page>
    ) : (
        scrollerContents
    )
}

export default ClipScroller
