import { Image } from 'components/Image'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

export const CardWrapper = styled.a`
    display: flex;
    flex-direction: column;
    margin: 0 0.25rem;

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        width: 324px;
        .org-logo {
            scale: 0.95;
            transition-duration: 0.2s;
        }

        :hover .thumbnail-card-background {
            scale: 1.02;
        }

        :hover .org-logo {
            scale: 1;
        }
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 272px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 222px;
    }
`

export const ThumbnailCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    height: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    color: white;
    overflow: hidden;

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        height: 210px;
        width: 324px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        height: 200px;
        width: 272px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        height: 150px;
        width: 222px;
    }
`
export const CardHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 2;
`

export const LiveWrapper = styled.span`
    border-radius: 3em;
    padding: 0.3em 0.9rem;
    margin: 0.55em;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    background-color: #fa3737;
`

export const TeamLogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 4px 8px 0 8px;
    z-index: 2;
    position: absolute;
`

export const CardFooterWrapper = styled.div<{ upcoming?: boolean }>`
    h3 {
        font-size: ${({ upcoming }) => (upcoming ? '18px' : '16px')};
        font-weight: 700;
        line-height: 1.1em;
        color: white;
        padding-bottom: 3px;
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    padding-left: ${({ upcoming }) => (upcoming ? '0.3em' : '0.75em')};
    padding-right: ${({ upcoming }) => (upcoming ? '0.3em' : '0.75em')};
    padding-top: ${({ upcoming }) => (upcoming ? '10px' : '24px')};
    padding-bottom: 0.25rem;
    align-items: flex-end;
    width: 100%;
    color: white !important;
    z-index: 2;
    letter-spacing: -0.4px;
    ${({ upcoming }) => {
        return upcoming
            ? ''
            : `background: linear-gradient( 0deg, rgba(25, 25, 25, 0.9) 0%, rgba(25, 25, 25, 0.78) 40%, rgba(25, 25, 25, 0.5) 70%, rgba(29, 29, 29, 0) 100%);`
    }};

    @media (min-width: 768px) {
        padding-left: ${({ upcoming }) => (upcoming ? '0.4em' : '1.25em')};
        padding-right: ${({ upcoming }) => (upcoming ? '0.5em' : '1.25em')};
        padding-top: ${({ upcoming }) => (upcoming ? '10px' : '28px')};
    }
`

export const UserWrapper = styled.span`
    display: flex;
    padding: 0.125rem;
    justify-content: space-around;
    align-items: center;
    width: 3rem;
    background-color: rgba(29, 29, 29, 0.8);
`

export const OverlayWrapper = styled.div`
    z-index: 3;
    position: absolute;
    right: 0;
    bottom: 0;

    #telethon-overlay {
        border-bottom-right-radius: 0.375rem;
    }

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        min-height: 200px;
        max-height: 200px;
        min-width: 120px;
        max-width: 120px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        min-height: 160px;
        max-height: 160px;
        min-width: 90px;
        max-width: 90px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        min-height: 100px;
        max-height: 100px;
        min-width: 60px;
        max-width: 60px;
    }
`

export const CardBackground = styled(Image)`
    z-index: 1;
    transition: 0.23s ease-out;
`

export const FooterContentWrapper = styled.div<{
    fullWidth?: boolean
    upcoming?: boolean
}>`
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: ${({ upcoming }) => (upcoming ? '4px' : '0px')};

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        width: ${(props) => (props.fullWidth ? '100%' : '70%')};
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: ${(props) => (props.fullWidth ? '100%' : '70%')};
        font-size: ${(props) => (props.fullWidth ? '0.875rem' : '0.8rem')};
        line-height: ${(props) => (props.fullWidth ? '1.25rem' : '1.15rem')};
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: ${(props) => (props.fullWidth ? '100%' : '55%')};
        font-size: ${(props) => (props.fullWidth ? '0.875rem' : '0.8rem')};
        line-height: ${(props) => (props.fullWidth ? '1.25rem' : '1rem')};

        & > strong {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        & > time {
            font-size: ${(props) => (props.fullWidth ? '0.875rem' : '0.8rem')};
            line-height: ${(props) => (props.fullWidth ? '1.25rem' : '1rem')};
        }
    }
`
