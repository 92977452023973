import corner1 from 'assets/images/telethon/corner1.svg'
import corner2 from 'assets/images/telethon/corner2.svg'
import corner3 from 'assets/images/telethon/corner3.svg'
import corner4 from 'assets/images/telethon/corner4.svg'
import { Image } from 'components/Image'
import useMediaQuery from 'hooks/useMediaQuery'
import Link from 'next/link'
import { ClubContext } from 'pages/club/[club_slug]'
import { FC, useContext, useMemo } from 'react'
import { FaRegUser } from 'react-icons/fa'
import { defaultBGImage } from 'utils/constData'
import { trackEvent } from 'utils/helper-amplitude'
import { datetimeToLocal } from 'utils/helper-date'
import { siteSettings, useUser } from '../../../hooks'
import useMatchUrl from '../../../hooks/matchUrl'
import {
    EventCardProps,
    GameCardProps,
} from '../../../types/components/GameCard'
import {
    CardBackground,
    CardFooterWrapper,
    CardHeaderWrapper,
    CardWrapper,
    FooterContentWrapper,
    LiveWrapper,
    OverlayWrapper,
    TeamLogoWrapper,
    ThumbnailCardContainer,
    UserWrapper,
} from './ThumbnailCard.style'

function getCornerImage(id: number) {
    const cornerImages = [corner1, corner2, corner3, corner4]
    const Index = id % 4
    return cornerImages[Index]
}

const ThumbnailCard: FC<GameCardProps & EventCardProps> = ({
    mode = 'Day',
    ...props
}) => {
    let isTelethon = false

    if (mode !== 'Clip') {
        isTelethon = props?.club_slug === 'telethon2023'
    } else {
        isTelethon =
            props.clubId?.toString() ===
            process.env.NEXT_PUBLIC_TELETHON_CLUB_ID
    }

    const uri = useMatchUrl(mode, props)
    const hasOpponent = props.clubName1 !== props.clubName2
    const { user } = useUser()

    const club = useContext(ClubContext)

    const hasCustomOpponent = props.customOpponent ? true : false
    const imgDim = useMediaQuery(768) ? 80 : 130
    const upcoming = ['Day', 'Event'].includes(mode)

    const timeDisplay = useMemo(() => {
        return props.date
            ? datetimeToLocal(props.date, {
                  casual: true,
                  hideTimezone: true,
              })
            : ''
    }, [props.date])

    const handleTrackEvent = (e: any) => {
        trackEvent(user, `${props.event} (via ${props.page})`, {
            clipName: props.event === 'View Clip' ? props.title : '',
            matchID: props.event === 'View Clip' ? '' : props.id,
            league: props.page === 'League Page' ? props.leagueName : '',
            clubName: props.page === 'Club Page' ? club?.name : '',
        })

        if (props.event === 'View Clip') {
            e.preventDefault()
        }

        return typeof props.handleClick === 'function'
            ? props.handleClick(props.id)
            : undefined
    }

    const getBackgroundSource = useMemo(() => {
        if (
            ['Replay', 'Clip'].includes(mode) ||
            (mode == 'Day' && props.isLive)
        ) {
            return typeof props.backgroundImage === 'string'
                ? props.backgroundImage
                : props.backgroundImage?.src
        }

        return (
            props.backgroundImageLeague ??
            props.backgroundImageSport ??
            defaultBGImage
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.backgroundImage,
        props.backgroundImageSport,
        props.backgroundImageLeague,
    ])

    // console.log('-----------------------------', getBackgroundImage())

    const getLeagueName = useMemo(() => {
        if (!props.leagueName) return null

        if (!props.roundName && !props.is_event) return null

        if (props.is_event) return props.clubName

        return `${props.leagueName} - ${props.roundName}`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.leagueName, props.roundName, props.is_event])

    const TelethonOverlay = () => {
        return (
            <OverlayWrapper>
                <Image
                    id="telethon-overlay"
                    src={getCornerImage(props.id)}
                    alt="telethon cards overlay"
                    layout="fill"
                    objectFit="cover"
                />
            </OverlayWrapper>
        )
    }

    const CardHeader = () => {
        const showUserViewCount =
            mode === 'Day' && siteSettings('components.user_view_count')
        const showLiveLabel = upcoming && props.isLive

        return (
            <CardHeaderWrapper>
                {showLiveLabel ? <LiveWrapper>LIVE</LiveWrapper> : <span />}
                {showUserViewCount && (
                    <UserWrapper>
                        <FaRegUser />
                        <span>{props.users}</span>
                    </UserWrapper>
                )}
            </CardHeaderWrapper>
        )
    }

    const TeamLogo = () => {
        return (
            <TeamLogoWrapper>
                {['Day'].includes(mode) && (
                    <Image
                        src={props?.clubImage1 as string | undefined}
                        alt={props?.clubName1}
                        width={imgDim}
                        height={imgDim}
                        className="org-logo"
                    />
                )}

                {['Event'].includes(mode) && props?.event_type === 'club' && (
                    <Image
                        src={props?.clubImage1 as string | undefined}
                        alt={props?.clubName1}
                        width={isTelethon ? 190 : imgDim}
                        height={isTelethon ? 190 : imgDim}
                        className="org-logo"
                    />
                )}

                {['Event'].includes(mode) && props?.event_type === 'league' && (
                    <Image
                        src={props?.leagueImage as string | undefined}
                        alt={props?.leagueName}
                        width={imgDim}
                        height={imgDim}
                        className="org-logo"
                    />
                )}

                {hasOpponent &&
                    !hasCustomOpponent &&
                    !props.is_event &&
                    mode === 'Day' && (
                        <Image
                            src={props?.clubImage2 as string | undefined}
                            alt={props?.clubName2}
                            width={imgDim}
                            height={imgDim}
                            className="org-logo"
                        />
                    )}
            </TeamLogoWrapper>
        )
    }

    const CardFooter = () => {
        const showMatchDetails = ['Day', 'Replay', 'Event'].includes(mode)

        return (
            <CardFooterWrapper upcoming={upcoming}>
                {showMatchDetails ? (
                    <FooterContentWrapper
                        fullWidth={!isTelethon}
                        upcoming={upcoming}
                    >
                        {props?.is_event ? (
                            <h3>{props.roundName ?? props.event_name}</h3>
                        ) : (
                            <h3>
                                <span>{props.clubName1}</span>
                                {hasOpponent && !hasCustomOpponent && (
                                    <span>
                                        {' vs '}
                                        {props.clubName2}
                                    </span>
                                )}
                                {hasCustomOpponent && (
                                    <span>
                                        {' vs '}
                                        {props.customOpponent.club_name}
                                    </span>
                                )}
                            </h3>
                        )}

                        <p className={'text-sm leading-4 font-normal'}>
                            {isTelethon ? null : getLeagueName}
                        </p>
                        {props.date ? (
                            <p suppressHydrationWarning={true}>{timeDisplay}</p>
                        ) : null}
                    </FooterContentWrapper>
                ) : (
                    <FooterContentWrapper fullWidth={!isTelethon}>
                        {props.title && <h3>{props.title}</h3>}
                        {props.desc && <p>{props.desc}</p>}
                    </FooterContentWrapper>
                )}
            </CardFooterWrapper>
        )
    }

    return (
        <Link href={props.href ?? uri} passHref shallow={true} legacyBehavior>
            <CardWrapper onClick={handleTrackEvent}>
                <ThumbnailCardContainer>
                    {isTelethon ? <TelethonOverlay /> : null}
                    <CardHeader />
                    <TeamLogo />
                    {!upcoming && <CardFooter />}
                    <CardBackground
                        className="thumbnail-card-background"
                        src={getBackgroundSource}
                        quality={100}
                        mode="fill"
                        oFit="cover"
                        alt=""
                        placeholder={`${process.env.NEXT_PUBLIC_ASSETS_BUCKET_CDN_URL}/default-streamer.jpg`}
                        retryTime={4000}
                    />
                </ThumbnailCardContainer>
                {upcoming && <CardFooter />}
            </CardWrapper>
        </Link>
    )
}

export default ThumbnailCard
